import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { AppRoutes } from './app.routes';

import { AgmCoreModule } from '@agm/core';
import { AgmDirectionModule } from 'agm-direction';

import { NgIdleKeepaliveModule } from '@ng-idle/keepalive'; // this includes the core NgIdleModule but includes keepalive providers for easy wireup

//modules
import { SharedModule } from './shared/shared.module';
import { ClipboardModule } from '@angular/cdk/clipboard';

//Component
import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { LoginComponent } from './account/login/login.component';
import { PatientComponent } from './patient/patient.component';
import { AutoLogoutComponent } from './account/auto-logout/auto-logout.component';
import { RegisterComponent } from './account/register/register.component';
import { PatientDetailsComponent } from './patient/patient-details.component';
import { PatientOrderComponent } from './patient-order/patient-order.component';
import { PatientOrderDetailsComponent } from './patient-order/patient-order-details.component';
import { PhlebotomistComponent } from './phlebotomist/phlebotomist.component';
import { VisitsComponent } from './patient-order/visit/visits.component';
import { VisitDetailsComponent } from './patient-order/visit/visit-details.component';
import { ChangePasswordComponent } from './account/change-password/change-password.component';
import { UsersComponent } from './account/users/users.component';
import { Full_CalendarComponent } from './full-calendar/full-calendar.component';
import { FullMapComponent } from './map/full-map.component';
import { RouteTrackingComponent } from './phlebotomist/route-tracking/route-tracking.component';
import { NeighborhoodComponent } from './neighborhood/neighborhood.component';
import { SharedVisitComponent } from './patient-order/visit/shared-visit.component';
import { DownloadAppComponent } from './download-app-mobile/download-app.component';
import { PopUpEditPatientComponent } from './patient/pop-up/pop-up-edit-patient.component';
import { SurveyComponent } from './survey/survey.component';
import { QuickAddComponent } from './quick-add/quick-add.component';
import { DocumentViewerComponent } from './document-viewer/document-viewer.component';
import { AbsentComponent } from './phlebotomist/absent/absent.component';
import { PatientOrderAppMobileComponent } from './patient-order-app-mobile/patient-order-app-mobile.component';
import { PatientOrderAppMobileDetailsComponent } from './patient-order-app-mobile/patient-order-app-mobile-details.component';
import { ImageViewerComponent } from './patient-order-app-mobile/image-viewer/image-viewer.component';
import { SampleDeliveryComponent } from './sample-delivery/sample-delivery.component';
import { SampleDeliveryAddNewEditComponent } from './sample-delivery/sample-delivery-addnew-edit.component';
 
//Services
import { AccountService } from './account/account.service';
import { UserLoggedInService } from './account/user-logged-in.service';
import { PatientService } from './patient/patient.service';
import { PostalCodeService } from './postal-code/postal-code.service';
import { PatientOrderService } from './patient-order/patient-order.service';
import { LaboratoryService } from './laboratory/laboratory.service';
import { PhlebotomistService } from './phlebotomist/phlebotomist.service';
import { VisitFilterService } from './patient-order/visit/visit-filter.service';
import { NeighborhoodService } from './neighborhood/neighborhood.service';
import { FileService } from './shared/service/file.service';
import { SurveyService } from './survey/survey.service';
import { PaginationService } from './shared/pagination/pagination.service';
import { ProcedureService } from './procedure/procedure.service';
import { OrderProcedureService } from './order-procedure/order-procedure.service';
import { QuickAddService } from './quick-add/quick-add.service';
import { QuickAddGlobalObjectService } from './quick-add/quick-add-global-object.service';
import { AbsentService } from './phlebotomist/absent/absent.service';
import { PatientOrderAppMobileService } from './patient-order-app-mobile/patient-order-app-mobile.service';
import { PatientOrderGlobalObjectService } from './patient-order-app-mobile/patient-order-global-objecte.service';
import { SampleDeliveryService } from './sample-delivery/sample-delivery.service';


//Interceptors
import { AuthInterceptor } from './account/auth.interceptor';
import { HttpErrorInterceptor } from './core/http-error-interceptor';

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    LoginComponent,
    PatientComponent,
    AutoLogoutComponent,
    RegisterComponent,
    PatientDetailsComponent,
    PatientOrderComponent,
    PatientOrderDetailsComponent,
    PhlebotomistComponent,
    VisitsComponent,
    VisitDetailsComponent,
    ChangePasswordComponent,
    UsersComponent,
    Full_CalendarComponent,
    FullMapComponent,
    RouteTrackingComponent,
    NeighborhoodComponent,
    SharedVisitComponent,
    DownloadAppComponent,
    PopUpEditPatientComponent,
    SurveyComponent,
    QuickAddComponent,
    DocumentViewerComponent,
    AbsentComponent,
    PatientOrderAppMobileComponent,
    PatientOrderAppMobileDetailsComponent,
    ImageViewerComponent,
    SampleDeliveryComponent,
    SampleDeliveryAddNewEditComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    SharedModule,
    AppRoutes,
    //RouterModule.forRoot([
    //  { path: '', component: VisitsComponent, pathMatch: 'full' },
    //  { path: 'login', component: LoginComponent },
    //  { path: 'patient', component: PatientComponent },
    //  { path: 'register', component: RegisterComponent },
    //  { path: 'patient-details/:id', component: PatientDetailsComponent },
    //  { path: 'patient-orders/:patientId', component: PatientOrderComponent },
    //  { path: 'patient-order-details/:patientId/:orderId', component: PatientOrderDetailsComponent },
    //  { path: 'phlebotomist', component: PhlebotomistComponent },
    //  { path: 'visits', component: VisitsComponent },
    //  { path: 'visit-details/:orderId', component: VisitDetailsComponent },
    //  { path: 'change-password', component: ChangePasswordComponent },
    //  { path: 'users', component: UsersComponent },
    //  { path: 'full-calendar', component: Full_CalendarComponent },
    //  { path: 'full-map', component: FullMapComponent },
    //  { path: 'route-tracking/:phlebotomistId', component: RouteTrackingComponent },
    //  { path: 'shared-visit/:patientId/:orderId', component: SharedVisitComponent },
    //  {path: '**', redirectTo: ''}
    //]),
    NgIdleKeepaliveModule.forRoot(),
    ClipboardModule,
    AgmCoreModule.forRoot({
      //apiKey: 'AIzaSyDqyTz2xSK4L0Tvrd_ZrFIwyvAhgGhKRn0',
      apiKey: 'AIzaSyDuy4pcq0Rg_sEjnMxsqGJQkWrvDQAC__k',
      libraries: ['places']
    }),
    AgmDirectionModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    AccountService,
    UserLoggedInService,
    PatientService,
    PostalCodeService,
    PatientOrderService,
    LaboratoryService,
    PhlebotomistService,
    VisitFilterService,
    NeighborhoodService,
    FileService,
    SurveyService,
    PaginationService,
    ProcedureService,
    OrderProcedureService,
    QuickAddService,
    QuickAddGlobalObjectService,
    AbsentService,
    PatientOrderAppMobileService,
    PatientOrderGlobalObjectService,
    SampleDeliveryService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
