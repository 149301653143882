// Angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { BaseService } from '../core/base.service';

// Objects
import { SampleDelivery } from './sample-delivery';
import { SampleDeliveryDetails } from './sample-delivery-details';
import { SampleType } from './sample-type';
import { SignaturePinCode } from './signature-pincode';
import { FilterSampleDeliveryParams } from '../patient-order/model/filter-params';

@Injectable()
export class SampleDeliveryService extends BaseService {

  private readonly apiUrl = 'api/sampledelivery';

  public constructor(private readonly http: HttpClient) {
    super();
  }

  public getSampleDelivery(id: number): Observable<SampleDelivery> {

    return this.http.get<SampleDelivery>(`${this.apiUrl}/${id}`);
  }

  public getSampleDeliveries(): Observable<SampleDelivery[]> {

    return this.http.get<SampleDelivery[]>(this.apiUrl);
  }

  public getSampleTypes(): Observable<SampleType[]> {

    return this.http.get<SampleType[]>(`${this.apiUrl}/get-sample-types`);
  }

  public getSignaturePinCode(pincode: number): Observable<SignaturePinCode> {

    return this.http.get<SignaturePinCode>(`${this.apiUrl}/get-signature-pincode/${pincode}`);
  }

  public getSignaturePinCodes(): Observable<SignaturePinCode[]> {

    return this.http.get<SignaturePinCode[]>(`${this.apiUrl}/get-signature-pincodes`);
  }

  public postSampleDelivery(sampleDelivery: SampleDelivery): Observable<SampleDelivery> {

    sampleDelivery.setDeliveryDate = `${sampleDelivery.deliveryDate.toLocaleDateString('en-US')} ${sampleDelivery.deliveryDate.toLocaleTimeString('en-US')}`;

    return this.http.post<SampleDelivery>(`${this.apiUrl}`, sampleDelivery, { headers: this.PutPostHeaders });
  }

  public putSampleDelivery(sampleDelivery: SampleDelivery): Observable<any> {

    sampleDelivery.setDeliveryDate = `${sampleDelivery.deliveryDate.toLocaleDateString('en-US')} ${sampleDelivery.deliveryDate.toLocaleTimeString('en-US')}`;

    return this.http.put<any>(this.apiUrl, sampleDelivery, { headers: this.PutPostHeaders });
  }

  public postSampleDeliveryDetails(sampleDeliveryDetails: SampleDeliveryDetails): Observable<SampleDeliveryDetails> {

    return this.http.post<SampleDeliveryDetails>(`${this.apiUrl}/add-sample-delivery-details`, sampleDeliveryDetails, { headers: this.PutPostHeaders });
  }

  public putSampleDeliveryDetails(sampleDeliveryDetails: SampleDeliveryDetails): Observable<any> {

    return this.http.put<any>(`${this.apiUrl}/edit-sample-delivery-details`, sampleDeliveryDetails, { headers: this.PutPostHeaders });
  }

  public postSignaturePincode(signaturePincode: SignaturePinCode): Observable<SignaturePinCode> {

    return this.http.post<SignaturePinCode>(`${this.apiUrl}/add-signature-pincode`, signaturePincode, { headers: this.PutPostHeaders });
  }

  public deleteSampleDelivery(id: number): Observable<any> {

    return this.http.delete<any>(`${this.apiUrl}/${id}`);
  }

  public deleteSampleDeliveryDetails(id: number): Observable<any> {

    return this.http.delete<any>(`${this.apiUrl}/delete-sample-delivery-details/${id}`);
  }

  public deleteSignaturePincode(id: number): Observable<any> {

    return this.http.delete<any>(`${this.apiUrl}/delete-signature-pincode/${id}`);
  }

  public getSampleDeliveryReport(fromDate: Date, toDate: Date, phlebotomist: string): Observable<Blob> {

    /* tslint:disable:no-magic-numbers */
    fromDate.setHours(0, 0, 0, 0);
    toDate.setHours(23, 59, 59, 999);
    /* tslint:enable:no-magic-numbers */

    const fParams = {
      fromDate: `${fromDate.toLocaleDateString('en-US')} ${fromDate.toLocaleTimeString('en-US')}`,
      toDate: `${toDate.toLocaleDateString('en-US')} ${toDate.toLocaleTimeString('en-US')}`,
      phlebotomistName: phlebotomist
    } as FilterSampleDeliveryParams;

    return this.http.post(`${this.apiUrl}/sample-delivery-report`, fParams, { headers: this.PutPostHeaders, responseType: 'blob' });
  }

}
