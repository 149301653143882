import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';

//Services
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { SampleDeliveryService } from './sample-delivery.service';
import { AccountService } from '../account/account.service';
import { PatientService } from '../patient/patient.service';
import { LaboratoryService } from '../laboratory/laboratory.service';
import { PhlebotomistService } from '../phlebotomist/phlebotomist.service';

//Core
import { SampleDelivery } from './sample-delivery';
import { SampleDeliveryDetails } from './sample-delivery-details';
import { User } from '../account/user';
import { Patient } from '../patient/patient';
import { Laboratory } from '../laboratory/laboratory';
import { SampleType } from './sample-type';
import { SignaturePinCode } from './signature-pincode';
import { Phlebotomist } from '../phlebotomist/phlebotomist';

//Primeng
import { MessageService, SelectItem } from 'primeng/api';
import { Table } from 'primeng/table';

@Component({
  selector: 'app-sample-delivery',
  templateUrl: './sample-delivery.component.html'
})
export class SampleDeliveryComponent extends OnDestroyMixin implements OnInit {

  title = 'SAMPLE DELIVERY';
  public userLogged: User;
  public sampleDeliveries: SampleDelivery[] = [];
  public sampleDeliveryDetails: SampleDeliveryDetails = {} as SampleDeliveryDetails;
  public patients: Patient[] = [];
  public sampleTypes: SampleType[] = [];
  public selectSampleType: SampleType = {} as SampleType;
  public loading = false;
  public displayPatientDialog = false;
  public cols: any[];
  public iconSpinner = false;
  public laboratories: Laboratory[] = [];
  public laboratoryOptions: SelectItem[] = [];
  public displayDetailsDialog = false;
  public progressSpinner = false;
  public isNewRecord = false;
  public dialogHeader: string;
  public deleteModal = false;
  public deleteModalDetails = false;
  public id = 0;
  public detailsId = 0;
  public isAdmin = false;
  public displayAddNewSignature = false;
  public signaturePinCode: SignaturePinCode = {} as SignaturePinCode;
  public signaturePinCodes: SignaturePinCode[] = [];
  public selectPinCode: SignaturePinCode;
  public displaySetFilterParams = false;
  public fromDate: Date;
  public toDate: Date;
  public phlebotomists: Phlebotomist[] = [];
  public selectedPhlebotomist: Phlebotomist;
  public waitingScreen = false;
  @ViewChild('dt') table: Table;
  @ViewChild('detailsForm', { static: false }) public detailsForm: NgForm;

  constructor(private readonly sampleDeliveryService: SampleDeliveryService,
    private readonly accountService: AccountService,
    private readonly patientService: PatientService,
    private readonly laboratoryService: LaboratoryService,
    private readonly phlebotomistService: PhlebotomistService,
    private readonly messageService: MessageService,
    private readonly router: Router) {
    super();

    this.fromDate = new Date();
    this.toDate = new Date();
  }

  public ngOnInit(): void {

    if (this.isUserLoggedIn()) {

      this.userLogged = this.getlocalUser();      
      this.getSampleDeliveries();
    } else {

      this.router.navigate(['/login']);
    }
  }

  public getSampleDeliveries(): void {

    this.loading = true;

    this.sampleDeliveryService.getSampleDeliveries()
      .pipe(untilComponentDestroyed(this))
      .subscribe((data) => this.sampleDeliveries = data as SampleDelivery[],
        (error: any) => {
          this.loading = false;
          this.messageService.add({ key: 'sampledeliverykey', severity: 'error', summary: 'Error', detail: error });
        },
        () => {
          //console.log(this.sampleDeliveries);
          this.loading = false;
          this.getLaboratories();
        });
  }

  public showPatientDialog(): void {
    this.iconSpinner = true;
    //this.displayPatientDialog = true;
    this.getPatients();
  }

  public getPatients(): void {

    this.loading = true;

    this.patientService.getPatients()
      .pipe(untilComponentDestroyed(this))
      .subscribe((data) => {

        this.patients = data as Patient[];
        this.loadPatientColumns();
        this.loading = false;
        this.iconSpinner = false;
        this.displayPatientDialog = true;
      },
        (error: any) => {
          this.loading = false;
          this.messageService.add({ key: 'sampledeliverykey', severity: 'error', summary: 'Error', detail: error });
        });
  }

  public loadPatientColumns(): void {

    this.cols = [
      { field: 'firstName', header: 'FirstName' },
      { field: 'middleName', header: 'MiddleName' },
      { field: 'lastName', header: 'LastName' },
      { field: 'secondLastName', header: 'SecondLastName' },
      { field: 'physicalCity', header: 'City' },
      { field: 'birthDate', header: 'BirthDate' },
      { field: 'phoneNumber', header: 'Phone' }
    ];
  }

  public addNewRecord(patientId: number): void {
    this.displayPatientDialog = false;
    this.router.navigate([`/sample-delivery-addnew-edit/${patientId}/0`])
  }

  public onDateSelect(value: any) {
    this.table.filter(this.formatDate(value), 'deliveryDate', 'contains')
    console.log(this.formatDate(value));
  }

  public formatDate(date: any) {
    let month = date.getMonth() + 1;
    let day = date.getDate();

    if (month < 10) {
      month = '0' + month;
    }

    if (day < 10) {
      day = '0' + day;
    }

    return date.getFullYear() + '-' + month + '-' + day;
    //return month + '/' + day + '/' + date.getFullYear() + ' 12:00:00 AM';
  }

  public getLaboratories(): void {

    this.laboratoryService.getUserDefaultLaboratories(this.userLogged.id)
      .pipe(untilComponentDestroyed(this))
      .subscribe((data) => this.laboratories = data as Laboratory[],
        (error: any) => {

          this.messageService.add({ key: 'sampledeliverykey', severity: 'error', summary: 'Error', detail: error });
        },
        () => {

          let newRow: SelectItem = { label: 'Clear', value: '' };
          this.laboratoryOptions.push(newRow);

          for (let lab of this.laboratories) {
            let newRow: SelectItem = {label: lab.name, value: lab.name};
            this.laboratoryOptions.push(newRow);
          }
          this.getSampleTypes();
        });
  }

  public getSampleTypes(): void {

    this.sampleDeliveryService.getSampleTypes()
      .pipe(untilComponentDestroyed(this))
      .subscribe((data) => this.sampleTypes = data as SampleType[],
        (error: any) => {

          this.messageService.add({ key: 'sampledeliverykey', severity: 'error', summary: 'Error', detail: error });
        },
        () => {
          //console.log(this.sampleTypes);
          this.getSignaturePincodes();
        });
  }

  public addNewDetails(id: number): void {

    this.isNewRecord = true;
    this.dialogHeader = `Add New Record: ${id}`;
    this.detailsForm.reset();
    this.sampleDeliveryDetails.id = 0;
    this.sampleDeliveryDetails.sampleDeliveryId = id;
    this.displayDetailsDialog = true;
  }

  public editDetails(sampleDeliveryDetail: SampleDeliveryDetails): void {

    this.isNewRecord = false;
    this.dialogHeader = `Edit Record: ${sampleDeliveryDetail.sampleDeliveryId}`;
    this.sampleDeliveryDetails = sampleDeliveryDetail;
    if (this.sampleDeliveryDetails !== undefined) {
      const filterSampleTypes = this.sampleTypes.filter(a => a.description === this.sampleDeliveryDetails.description );
      if (filterSampleTypes !== undefined) {
        this.selectSampleType = filterSampleTypes[0];
      }
    }
    this.displayDetailsDialog = true;
  }

  public onSubmit(): void {

    this.progressSpinner = true;

    if (this.isNewRecord) {

      this.sampleDeliveryService.postSampleDeliveryDetails(this.sampleDeliveryDetails)
        .pipe(untilComponentDestroyed(this))
        .subscribe((data) => this.sampleDeliveryDetails = data as SampleDeliveryDetails,
          (error: string) => {

            this.progressSpinner = false;
            this.messageService.add({ key: 'sampledeliverykey', severity: 'error', summary: 'Error', detail: error });
          },
          () => {

            this.messageService.add({ key: 'sampledeliverykey', severity: 'success', summary: 'Record Created', detail: 'Record was created successfully.' });
            this.progressSpinner = false;
            this.displayDetailsDialog = false;
            this.getSampleDeliveries();
          });
    }
    else {

      this.sampleDeliveryService.putSampleDeliveryDetails(this.sampleDeliveryDetails)
        .subscribe((res: any) => {

          this.messageService.add({ key: 'sampledeliverykey', severity: 'success', summary: 'Record Edited', detail: `The record was edited successfully.` });
        },
          (error: any) => {

            this.progressSpinner = false;
            this.messageService.add({ key: 'sampledeliverykey', severity: 'error', summary: 'Error', detail: error });
          },
          () => {
            this.progressSpinner = false;
            this.displayDetailsDialog = false;
            this.getSampleDeliveries();
          });
    }
  }

  public showDeleteConf(id: number): void {

    this.id = id;
    this.deleteModal = true;
  }

  public delete(): void {

    this.deleteModal = false;

    this.sampleDeliveryService.deleteSampleDelivery(this.id)
      .subscribe((res: any) => {

        this.messageService.add({ key: 'sampledeliverykey', severity: 'success', summary: 'Delete', detail: 'Record deleted successfully' });
      },
        (error: any) => {

          this.messageService.add({ key: 'sampledeliverykey', severity: 'error', summary: 'Error', detail: error });
        },
        () => {

          this.getSampleDeliveries();
        });
  }

  public showDeleteConfDetails(id: number, detailsId: number): void {

    this.id = id;
    this.detailsId = detailsId;
    this.deleteModalDetails = true;
  }

  public deleteDetails(): void {

    this.deleteModalDetails = false;

    this.sampleDeliveryService.deleteSampleDeliveryDetails(this.detailsId)
      .subscribe((res: any) => {

        this.messageService.add({ key: 'sampledeliverykey', severity: 'success', summary: 'Delete', detail: 'Record deleted successfully' });
      },
        (error: any) => {

          this.messageService.add({ key: 'sampledeliverykey', severity: 'error', summary: 'Error', detail: error });
        },
        () => {

          const filterSampleDelivery = this.sampleDeliveries.filter(a => a.id === this.id);
          if (filterSampleDelivery !== undefined) {            
            const index = this.findIndexByKeyValue(filterSampleDelivery[0].sampleDeliveryDetails, this.detailsId);
            filterSampleDelivery[0].sampleDeliveryDetails.splice(index, 1);            
          }
        });
  }

  public findIndexByKeyValue(array: SampleDeliveryDetails[], id: number) {
    for (var i = 0; i < array.length; i++) {
      if (array[i].id === id) {
        return i;
      }
    }
    return -1;
  }

  public onChangeSampleType(event: any): void {

    this.sampleDeliveryDetails.description = event.description;
  }

  public editSampleDelivery(id: number, patientId: number): void {

    this.router.navigate([`/sample-delivery-addnew-edit/${patientId}/${id}`])
  }

  public displayAllButtons() {

    if (this.isAdmin) {
      for (let row of this.sampleDeliveries) {
        row.signature = false;
      }
    } else {
      this.messageService.add({ key: 'sampledeliverykey', severity: 'warn', summary: 'Warn', detail: 'Only user admin.' });
    }
    
  }

  public addNewSignaturePincode() {

    if (!this.isAdmin) {
      this.messageService.add({ key: 'sampledeliverykey', severity: 'warn', summary: 'Warn', detail: 'Only user admin.' });
      return;
    }

    this.signaturePinCode.id = 0;

    this.sampleDeliveryService.postSignaturePincode(this.signaturePinCode)
      .pipe(untilComponentDestroyed(this))
      .subscribe((data) => this.signaturePinCode = data as SignaturePinCode,
        (error: string) => {

          this.displayAddNewSignature = false;
          this.messageService.add({ key: 'sampledeliverykey', severity: 'error', summary: 'Error', detail: error });
        },
        () => {

          this.displayAddNewSignature = false;
          this.signaturePinCode = {} as SignaturePinCode;
          this.messageService.add({ key: 'sampledeliverykey', severity: 'success', summary: 'Record Created', detail: 'Record was created successfully.' });          
        });
  }

  public getSignaturePincodes(): void {

    this.sampleDeliveryService.getSignaturePinCodes()
      .pipe(untilComponentDestroyed(this))
      .subscribe((data) => this.signaturePinCodes = data as SignaturePinCode[],
        (error: any) => {

          this.messageService.add({ key: 'sampledeliverykey', severity: 'error', summary: 'Error', detail: error });
        },
        () => {
          //console.log(this.signaturePinCodes);
          this.getPhlebotomists();
        });
  }

  public deleteSignaturePincode() {

    if (!this.isAdmin) {
      this.messageService.add({ key: 'sampledeliverykey', severity: 'warn', summary: 'Warn', detail: 'Only user admin.' });
      return;
    }

    if (this.selectPinCode !== undefined) {

      this.sampleDeliveryService.deleteSignaturePincode(this.selectPinCode.id)
        .subscribe((res: any) => {

          this.messageService.add({ key: 'sampledeliverykey', severity: 'success', summary: 'Delete', detail: 'Record deleted successfully' });
        },
          (error: any) => {

            this.messageService.add({ key: 'sampledeliverykey', severity: 'error', summary: 'Error', detail: error });
          },
          () => {
            this.displayAddNewSignature = false;
            this.getSignaturePincodes();
          });
    } else {
      this.messageService.add({ key: 'sampledeliverykey', severity: 'warn', summary: 'Warn', detail: 'Select first name and try again.' });
    }
  }

  public getPhlebotomists(): void {

    this.phlebotomists = [];

    this.phlebotomistService.getPhlebotomists()
      .pipe(untilComponentDestroyed(this))
      .subscribe((data) => this.phlebotomists = data as Phlebotomist[],
        (error: any) => {
          console.log(error);
        });
  }

  public getSampleDeliveryReport() {
    this.displaySetFilterParams = false;
    this.waitingScreen = true;
    this.sampleDeliveryService.getSampleDeliveryReport(this.fromDate, this.toDate, this.selectedPhlebotomist.name).subscribe(blob => {
      const objectUrl = URL.createObjectURL(blob);
      window.open(objectUrl);
      this.waitingScreen = false;
      //saveAs(blob, 'TestReport.' + this.selectedFormat.toLowerCase());
    });
  }

  public isUserLoggedIn(): boolean {

    return this.accountService.isUserAuthenticated();
  }

  public getlocalUser(): User {

    let userLogged = this.accountService.getLoggedInUser();

    if (userLogged == undefined) {
      this.router.navigate(['/login'])
    }

    const userRoles = userLogged.roles.filter(row => row === 'Admin' || row === 'SuperAdmin');

    if (userRoles !== undefined) {
      this.isAdmin = true;
    }
    else {
      this.isAdmin = false;
    }

    return userLogged;
  }

}
